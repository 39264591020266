import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "react-bootstrap/lib/Carousel"

import { DATA } from "../components/data"

const IndexPage = () => {
  const { realisations } = DATA

  return (
    <Layout>
      <SEO
        title="Strona główna"
        description="Firma Jarglass od ponad 30 lat specjalizuje się w produkcji luster łazienkowych i wystroju wnętrz lustrami. Służymy również fachowym doradztwem na etapie projektowania luster we wnętrzach. JARGLASS cechuje najwyższa jakość, krótkie terminy realizacji, konkurencyjne ceny."
      />
      <>
        <div className="landing-bg">
          <div class="container">
            <div class="main-slogan fadeInUp">
              <h1>Lustra</h1>
              <h4>Odbicie Twojej wyobraźni</h4>
            </div>
          </div>
        </div>

        <div id="about" className="about-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="about-text">
                  <h1>O nas</h1>
                  <div className="line-about-lg"></div>
                  <div>
                    <p>
                      Firma Jarglass od ponad 30 lat specjalizuje się w
                      produkcji luster łazienkowych i wystroju wnętrz lustrami.
                    </p>
                    <p>
                      Od początku swej działalności na rynku stosujemy zawsze
                      najnowsze technologie z zakresu obróbki i montażu luster.
                      Zdobyte doświadczenia i osiągnięty wysoki poziom
                      oferowanych usług zaowocowały zaufaniem jakim obdarzyli
                      nas dotychczasowi klienci.
                    </p>
                    <p>
                      W swojej pracy posługujemy się najwyższej jakości
                      materiałami renomowanych producentów, co w połączeniu z
                      jakością oferowanych przez JARGLASS usług sprawia, że na
                      nasze wykonawstwo udzielamy wieloletnich gwarancji.
                    </p>
                    <p>
                      Jesteśmy w pełni przygotowani do obsługi dużych inwestycji
                      na terenie całego kraju czego dowodem są nasze
                      dotychczasowe realizacje.
                    </p>
                    <p>
                      Nasza oferta obejmuje produkcję, dostawę oraz
                      profesjonalny montaż.
                    </p>
                    <p>
                      Wykonujemy lustra w oparciu o własne wzornictwo i
                      technologie, ale również w oparciu o powierzone projekty
                      zgodne z wymaganiami klienta.
                    </p>
                    <p>
                      Służymy również fachowym doradztwem na etapie
                      projektowania luster we wnętrzach.
                    </p>
                    <p>
                      <strong>
                        JARGLASS cechuje najwyższa jakość, krótkie terminy
                        realizacji, konkurencyjne ceny.
                      </strong>
                    </p>
                    <p>
                      <strong>Serdecznie zapraszamy do współpracy.</strong>
                    </p>
                  </div>
                  <Link to={`/products-page/`}>
                    <button className="btn-products m-t-sm">PRODUKTY</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div>
                  <div>
                    <img
                      src="img/how_img.jpg"
                      alt="Szklane drzwi"
                      className="products-img polygon-clip-rhomboid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="clients" className="clients-bg">
          <div className="container text-center">
            <div className="row">
              <div className="text-center">
                <h1>Nasi klienci</h1>
                <div className="line-about center-block"></div>
              </div>
              <div className="clients-container">
                <Carousel className="clients-slider">
                  <Carousel.Item>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/Hilton_International.svg"
                        alt="Hilton"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/new-sheraton-seeklogo.com.svg"
                        alt="Sheraton Grand"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/radisson-blu.svg"
                        alt="Radisson"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/doubletree-by-hilton-lodz_1.svg"
                        alt="Double Tree by Hilton"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/Hilton_Garden_Inn.svg"
                        alt="Hilton Garden Inn"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/Le Regina.svg"
                        alt="Mamaison"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/hampton-by-hilton.svg"
                        alt="Hampton by Hilton"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/IBB hotels ok.svg"
                        alt="IBB"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="col-md-4">
                      <img
                        className="clients-slider-img img-responsive novotel-slider-img"
                        src="img/realisations/logotypy hoteli/gotowe/Novotel.svg"
                        alt="Novotel"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/mercure-hotels-seeklogo.com_1.svg"
                        alt="Mercure"
                      />
                    </div>
                    <div className="col-md-4">
                      <img
                        className="clients-slider-img img-responsive"
                        src="img/realisations/logotypy hoteli/gotowe/ibis-hotels.svg"
                        alt="Ibis"
                      />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        <div id="realisations" className="realisations-bg">
          <div className="container text-center">
            <div className="row">
              <div className="text-center">
                <h1>Realizacje</h1>
                <div className="line-about center-block"></div>
              </div>
              <div className="realisations-container">
                <div className="flexbox">
                  {realisations.map(realisation => (
                    <div className="item">
                      <Link to={`/realisation-page/?${realisation.id}`}>
                        <img src={realisation.outside_url} alt={realisation.name} />
                        <p className="title">{realisation.name}</p>
                        <p className="subtitle">{realisation.location}</p>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default IndexPage
